import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layouts/en";
import SEO from "../components/seo";
import "../style/member-benefit.css";
import { Tabs } from "antd";
import $ from "jquery";


const MemberBenefitPage = (props) => {
  useEffect(() => {
    expandData();
  }, []);

  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState('benefit-all')
  const [activeKeyMobile, setMobileActiveKey] = useState('benefit-mobile-all')

  const expandData = () => {
    $(".benefit__mobile label").on("click", function () {
      $(this).toggleClass("benefit__tab--active");
    });
  };

  const specific_date = new Date('2022-01-09');
  const current_date = new Date();

  return (
    
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
          margin: 0px auto
        }
        .benefit__special .table--benefit {
          display: inline-table
        }
      `,
        }}></style>
      <Layout location={props.location}>
        <SEO
          title="More benefits as you grow your membership status "
          keyword="AYANA member benefits, benefits for member, AYANA rewards"
          decription="Become an AYANA Rewards member and you will receive more benefits as you stay. As you grow your membership level, you will receive more as well. Become a member now and start to earn points!"
        />
        {current_date.getTime() > specific_date.getTime() ? (
            <>
            <div id="contentWrap">
              <div id="index_content" className="admin-setting">
                <div className="benefit__banner">&nbsp;</div>
                <div className="benefit">
                  <div className="benefit__banner__mobile mobile--only">
                    <img
                      className="full-width"
                      src="https://resource.ayana.com/live/rewards/page/ARSB.jpg"
                    />
                  </div>
                  <div className="benefit__content">
                    <div className="container container--600">
                      <h1 className="text--center">Member Benefits</h1>
                      <p className="text--center">
                        AYANA Rewards has three membership levels, with each level
                        offering unique benefits and rewards. The more you spend,
                        the more benefits you can earn. Enjoy exclusive benefits
                        with every stay.
                      </p>
                    </div>
                    <div className="container container--1000">
                      <table className="table table--benefit table--benefit--main">
                        <thead>
                          <tr>
                            <th valign="middle">Program Overview</th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                              />
                              Silver
                            </th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                              />
                              Gold
                            </th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                              />
                              Platinum
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Minimum points required to upgrade membership level</td>
                            <td>0</td>
                            <td>5,000</td>
                            <td>10,000</td>
                          </tr>
                          <tr>
                            <td>Minimum points required annually to retain membership level</td>
                            <td>0</td>
                            <td>1,500</td>
                            <td>5,000</td>
                          </tr>
                          <tr>
                            <td>Points expiry duration</td>
                            <td>10 years</td>
                            <td>10 years</td>
                            <td>10 years</td>
                          </tr>
                          <tr>
                            <td>Tier expiry duration</td>
                            <td>1 year</td>
                            <td>1 year</td>
                            <td>1 year</td>
                          </tr>
                          <tr className="table--benefit--subheader">
                            <td colspan="4">Earn Points Benefits</td>
                          </tr>
                          <tr>
                            <td>Points Earned per IDR 10,000 Spent</td>
                            <td>1 point</td>
                            <td>1 point</td>
                            <td>1 point</td>
                          </tr>
                          <tr>
                            <td>Points Earned per IDR 15,000 Spent (Delonix Only)</td>
                            <td>1 point</td>
                            <td>1 point</td>
                            <td>1 point</td>
                          </tr>
                          <tr>
                            <td>Points Earned on Eligible Stays</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Points Earned on F&#38;B Purchases</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Points Earned on SPA Treatment Purchases</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr className="table--benefit--subheader">
                            <td colspan="4">Member Benefits</td>
                          </tr>
                          <tr>
                            <td>Member Exclusive Room Discounts</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Complimentary 1-Tier Room Upgrade*</td>
                            <td>&nbsp;</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Bonus Points on Eligible Spend </td>
                            <td>&nbsp;</td>
                            <td>10%</td>
                            <td>25%</td>
                          </tr>
                          <tr>
                            <td>Early Check-in*</td>
                            <td>&nbsp;</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Late Check-out*</td>
                            <td>&nbsp;</td>
                            <td>2:00 PM</td>
                            <td>4:00 PM</td>
                          </tr>
                          <tr>
                            <td>Wellness Program Benefits**</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Complimentary Gift When Staying During Birthday** </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Member Exclusive Discount at Flower Studio</td>
                            <td>10%</td>
                            <td>10%</td>
                            <td>10%</td>
                          </tr>
                          <tr>
                            <td>Member Exclusive Discount at Kawano Group Restaurant</td>
                            <td>10%</td>
                            <td>10%</td>
                            <td>10%</td>
                          </tr>
                        </tbody>
                      </table>
                      <small className="mart--lg" style={{ display: "block" }}>
                        * Based on availability
                        <br />
                        ** Varies by property 
                        <br />  
                        For more information please refer to our{" "}
                        <Link className="link" to="/terms-and-conditions-8">
                            Terms &amp; Conditions
                        </Link>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </>
            ) : (
            <>
            <div id="contentWrap">
              <div id="index_content" className="admin-setting">
                <div className="benefit__banner">&nbsp;</div>
                <div className="benefit">
                  <div className="benefit__banner__mobile mobile--only">
                    <img
                      className="full-width"
                      src="https://resource.ayana.com/live/rewards/page/ARSB.jpg"
                    />
                  </div>
                  <div className="benefit__content">
                    <div className="container container--600">
                      <h1 className="text--center">Member Benefits</h1>
                      <p className="text--center">
                        AYANA Rewards has three membership levels, with each level
                        offering unique benefits and rewards. The more you spend,
                        the more benefits you can earn. Enjoy exclusive benefits
                        with every stay.
                      </p>
                    </div>
                    <div className="container container--1000">
                      <table className="table table--benefit table--benefit--main">
                        <thead>
                          <tr>
                            <th valign="middle">Program Overview</th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                              />
                              Silver
                            </th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                              />
                              Gold
                            </th>
                            <th valign="middle">
                              <img
                                alt=""
                                className="marr--sm"
                                src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                              />
                              Platinum
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Minimum points required for membership upgrade</td>
                            <td>0</td>
                            <td>5,000</td>
                            <td>10,000</td>
                          </tr>
                          <tr>
                            <td>Points earned per IDR 10,000 spent*</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td>Extra bonus points</td>
                            <td>&nbsp;</td>
                            <td>10%</td>
                            <td>15%</td>
                          </tr>
                          <tr>
                            <td>Member exclusive room discount</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Member exclusive discount at Flower Studio</td>
                            <td>10%</td>
                            <td>10%</td>
                            <td>10%</td>
                          </tr>
                          <tr>
                            <td>Member exclusive discount at Kawano Restaurant</td>
                            <td>10%</td>
                            <td>10%</td>
                            <td>10%</td>
                          </tr>
                          <tr>
                            <td>Complimentary gift when staying during birthday</td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                            <td>
                              <img
                                alt="yes"
                                src="https://resource.ayana.com/live/rewards/icon/check.svg"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="benefit__special">
                      <h1>Special Property Benefits</h1>
                      <div className="container container--1200">
                        <Tabs
                          className="benefit__tab"
                          defaultActiveKey="benefit-all"
                          size="large"
                          activeKey={activeKey}
                          onChange={(activeKey) => setActiveKey(activeKey)}
                        >
                          <TabPane tab="Bali Hotels" key="benefit-all">
                            <table className="table table--benefit">
                              <thead>
                                <tr>
                                  <th valign="middle">Benefits</th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                    />
                                    Silver
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                    />
                                    Gold
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                    />
                                    Platinum
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Complimentary 1-Tier Room Upgrade*</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Discount on SPA Treatments</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                                <tr>
                                  <td>Early Check-In*</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Late Check-Out*</td>
                                  <td>&nbsp;</td>
                                  <td>4 PM</td>
                                  <td>6 PM</td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPane>

                          <TabPane tab="AYANA Midplaza JAKARTA" key="benefit-ayana">
                            <table className="table table--benefit">
                              <thead>
                                <tr>
                                  <th valign="middle">Benefits</th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                    />
                                    Silver
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                    />
                                    Gold
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                    />
                                    Platinum
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Complimentary Club Access and Benefits*</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Complimentary 1-Tier Room upgrade*</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Earn Points on F&amp;B Spending</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Link to="/preferred-hotels-resorts">Redeem Points for I Prefer reward Certificate</Link>

                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Early Check-In*&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Late Check-Out*</td>
                                  <td>2 PM</td>
                                  <td>4 PM</td>
                                  <td>6 PM</td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPane>

                          <TabPane
                            tab="Delonix Hotel Karawang"
                            key="benefit-delonix">
                            <table className="table table--benefit">
                              <thead>
                                <tr>
                                  <th valign="middle">Benefits</th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                    />
                                    Silver
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                    />
                                    Gold
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                    />
                                    Platinum
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>All Day Dine-in Discount at Miami Café</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                  <td>20%</td>
                                </tr>
                                <tr>
                                  <td>
                                    Discount on Business Center Facilities Usage
                                  </td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                </tr>
                                <tr>
                                  <td>Discount on Airport Drop and Pick Up</td>
                                  <td>20%</td>
                                  <td>20%</td>
                                  <td>25%</td>
                                </tr>
                                <tr>
                                  <td>
                                    Complimentary Usage of Futsal Field -1 Hour
                                  </td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Complimentary 1-Tier Room Upgrade*</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Earn Points on F&amp;B Spending</td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="yes"
                                      src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Early Check-In*&nbsp;</td>
                                  <td>

                                  </td>
                                  <td>
                                    1 PM
                                  </td>
                                  <td>
                                    1 PM
                                  </td>
                                </tr>
                                <tr>
                                  <td>Late Check-Out*</td>
                                  <td></td>
                                  <td>4 PM</td>
                                  <td>4 PM</td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPane>

                          <TabPane tab="AYANA Komodo Resort" key="benefit-komodo">
                            <table className="table table--benefit">
                              <thead>
                                <tr>
                                  <th valign="middle">Benefits</th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                    />
                                    Silver
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                    />
                                    Gold
                                  </th>
                                  <th className="text--center" valign="middle">
                                    <img
                                      alt=""
                                      className="marr--sm"
                                      src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                    />
                                    Platinum
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Discount on Land Activities</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                                <tr>
                                  <td>
                                    Discount on Rental Equipment for Water Sport
                                    Activities
                                  </td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                                <tr>
                                  <td>Discount on SPA Treatments</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                                <tr>
                                  <td>Discount on Boat Trip Activities*</td>
                                  <td>10%</td>
                                  <td>10%</td>
                                  <td>15%</td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPane>
                        </Tabs>
                        <small className="mart--lg" style={{ display: "block" }}>
                          *Benefits varies by brand and / or subject to
                          availability.
                          <br />
                          {activeKey == 'benefit-all' && (
                            <>
                              <span className="">
                                *Not eligible for SPA on the rock
                            </span>
                              <br />
                            </>
                          )}

                          {activeKey == 'benefit-komodo' && (
                            <>
                              <span className="">
                                *Not eligible for Lako Di’a Cruising Trip & Boat Charter
                            </span>
                              <br />
                            </>
                          )}
                          For more information please refer to our{" "}
                          <Link className="link" to="/terms-and-conditions-8">
                            Terms &amp; Conditions.
                          </Link>
                        </small>
                      </div>
                    </div>
                    <div className="benefit__mobile">
                      <h1 className="tablet--only">Special Property Benefits</h1>
                      <div className="container container--1000">
                        <label
                          className="benefit__tab--active"
                          htmlFor="benefit-mobile-all">
                          Bali Hotels
                        </label>
                        <input
                          defaultChecked={true}
                          id="benefit-mobile-all"
                          name="benefit"
                          type="checkbox"
                          onClick={() => setMobileActiveKey('benefit-mobile-all')}
                        />
                        <table className="table table--benefit">
                          <thead>
                            <tr>
                              <th valign="middle">Benefits</th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                />
                                Silver
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                />
                                Gold
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                />
                                Platinum
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Complimentary 1-Tier Room Upgrade*</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Discount on SPA Treatments</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>Early Check-In*&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Late Check-Out*</td>
                              <td>&nbsp;</td>
                              <td>4 PM</td>
                              <td>6 PM</td>
                            </tr>
                          </tbody>
                        </table>

                        <label htmlFor="benefit-mobile-ayana">
                          AYANA Midplaza JAKARTA
                        </label>

                        <input
                          id="benefit-mobile-ayana"
                          name="benefit"
                          type="checkbox"
                          onClick={() => setMobileActiveKey('benefit-mobile-ayana')}
                        />

                        <table className="table table--benefit">
                          <thead>
                            <tr>
                              <th valign="middle">Benefits</th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                />
                                Silver
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                />
                                Gold
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                />
                                Platinum
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Complimentary Club Access and Benefits*</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Complimentary 1-Tier Room Upgrade*</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Earn Points on F&amp;B Spending</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td><Link to="/preferred-hotels-resorts">Redeem Points for I Prefer reward Certificate</Link></td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Early Check-In*&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Late Check-Out*</td>
                              <td>2 PM</td>
                              <td>4 PM</td>
                              <td>6 PM</td>
                            </tr>
                          </tbody>
                        </table>

                        <label htmlFor="benefit-mobile-delonix">
                          Delonix Hotel Karawang
                        </label>
                        <input
                          id="benefit-mobile-delonix"
                          name="benefit"
                          type="checkbox"
                          onClick={() => setMobileActiveKey('benefit-mobile-delonix')}
                        />

                        <table className="table table--benefit">
                          <thead>
                            <tr>
                              <th valign="middle">Benefits</th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                />
                                Silver
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                />
                                Gold
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                />
                                Platinum
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Discount at Miami Cafe During Birthday</td>
                              <td>20%</td>
                              <td>20%</td>
                              <td>20%</td>
                            </tr>
                            <tr>
                              <td>Discount on Business Center Facilities Usage</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>10%</td>
                            </tr>
                            <tr>
                              <td>Discount on Airport Drop and Pick Up</td>
                              <td>20%</td>
                              <td>20%</td>
                              <td>25%</td>
                            </tr>
                            <tr>
                              <td>Complimentary Usage of Futsal Field -1 hour</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Complimentary 1-Tier Room Upgrade*</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Earn Points on F&amp;B Spending</td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                              <td>
                                <img
                                  alt="yes"
                                  src="https://resource.ayana.com/live/rewards/icon/check.svg"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Early Check-In*&nbsp;</td>
                              <td>

                              </td>
                              <td>
                                1 PM
                              </td>
                              <td>
                                1 PM
                              </td>
                            </tr>
                            <tr>
                              <td>Late Check-Out*</td>
                              <td></td>
                              <td>4 PM</td>
                              <td>4 PM</td>
                            </tr>
                          </tbody>
                        </table>
                        <label htmlFor="benefit-mobile-komodo">
                          AYANA Komodo Resort
                        </label>
                        <input
                          id="benefit-mobile-komodo"
                          name="benefit"
                          type="checkbox"
                          onClick={() => setMobileActiveKey('benefit-mobile-komodo')}
                        />
                        <table className="table table--benefit">
                          <thead>
                            <tr>
                              <th valign="middle">Benefits</th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-silver.png"
                                />
                                Silver
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-gold.png"
                                />
                                Gold
                              </th>
                              <th className="text--center" valign="middle">
                                <img
                                  alt=""
                                  className="marr--sm"
                                  src="https://resource.ayana.com/live/rewards/icon/card-platinum.png"
                                />
                                Platinum
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Discount on Land Activities</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>
                                Discount on Rental Equipment for Water Sport
                                Activities
                              </td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>Discount on SPA Treatments</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>Discount on Boat Trip Activities*</td>
                              <td>10%</td>
                              <td>10%</td>
                              <td>15%</td>
                            </tr>
                          </tbody>
                        </table>
                        <small className="mart--lg" style={{ display: "block" }}>
                          *Benefits varies by brand and / or subject to
                          availability.
                          <br />
                          {activeKeyMobile == 'benefit-mobile-all' && (
                            <>

                              <span className="">
                                *Not eligible for SPA on the rock
                              </span>
                              <br />
                            </>
                          )}
                          {activeKeyMobile == 'benefit-mobile-komodo' && (
                            <>

                              <span className="">
                                *Not eligible for Lako Di&rsquo;a Cruising Trip &amp;
                                Boat Charter
                              </span>
                              <br />
                            </>
                          )}
                          For more information please refer to our
                          <Link className="link" to="/terms-and-conditions-8">
                            Terms &amp; Conditions
                          </Link>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </>
        )}
      </Layout>
    </>
  );
};
export default MemberBenefitPage;
